import React, {Component} from 'react';
import {Card, Container, Header, Icon, Image,} from 'semantic-ui-react';
import styles from "./style.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import image from "./amazonsmilelogo.png"
import Img from "../utils/Img";

class AmazonSmile extends Component {
    render() {
        return (
            <div className={styles.container}>
                <MobileTabletContainer className={styles.mobile}>
                    <MobileBgWrapper>
                        <div className={styles.header}>
                            <h2><Icon name={'amazon'} color={'violet'}/>Einkaufen & Unterstützen mit:</h2>
                        </div>
                        <Container>
                            <div className={styles.main}>
                                <Card className={styles.card}>
                                    <a href={'https://smile.amazon.de/ch/143-235-12192'}>
                                        <Image className={styles.image}
                                               src={image}/>
                                    </a>
                                </Card>
                            </div>
                        </Container>
                    </MobileBgWrapper>
                </MobileTabletContainer>
                <DesktopContainer className={styles.desktop}>
                    <div className={styles.main}>
                        <Header as='h4' className={styles.header}>Einkaufen & Unterstützen mit:</Header>
                        <>
                            <Card className={styles.card}>
                                <a href={'https://smile.amazon.de/ch/143-235-12192'}>
                                    <Image className={styles.image}
                                         src={image}/>
                                </a>
                            </Card>
                        </>

                    </div>
                </DesktopContainer>
            </div>

        );
    }
}


export default AmazonSmile;

